
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      dataList: {} as any,
    });

    const activityOptionList = () => {
      store
        .dispatch("actActivityOptionList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.dataList = res.data_list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const goTo = (type: string, option: any) => {
      if (type == "week_received") {
        router.push({
          name: "weekReceived",
          query: {
            option: option,
          },
        });
      } else if (type == "week_amount") {
        router.push({
          name: "weekAmount",
          query: {
            option: option,
          },
        });
      } else if (type == "season") {
        router.push({
          name: "rankingSeason",
          query: {
            option: option,
          },
        });
      } else if (type == "design") {
        router.push({
          name: "rankingDesign",
          query: {
            option: option,
          },
        });
      }
    };

    activityOptionList();
    return { ...toRefs(dataMap), goTo };
  },
});
