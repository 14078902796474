<template>
  <div class="index hidden-scrollbar">
    <div v-if="dataList.length > 0">
      <div
        class="area"
        v-for="(area, index) in dataList"
        :key="index"
        v-show="area.is_show"
      >
        <div class="header">{{ area.title }}</div>
        <div class="box">
          <div class="flex">
            <div
              class="flex50"
              v-for="(col, ids) in area.option"
              :key="'col' + ids"
              @click="goTo(col.page_table, col.type)"
            >
              <p
                class="font-40 iconfont"
                :class="col.icon.replace('icon', 'icon-')"
                :style="{ color: col.color }"
              ></p>
              <p class="text">{{ col.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="none">暂无数据！</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      dataList: {} as any,
    });

    const activityOptionList = () => {
      store
        .dispatch("actActivityOptionList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.dataList = res.data_list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const goTo = (type: string, option: any) => {
      if (type == "week_received") {
        router.push({
          name: "weekReceived",
          query: {
            option: option,
          },
        });
      } else if (type == "week_amount") {
        router.push({
          name: "weekAmount",
          query: {
            option: option,
          },
        });
      } else if (type == "season") {
        router.push({
          name: "rankingSeason",
          query: {
            option: option,
          },
        });
      } else if (type == "design") {
        router.push({
          name: "rankingDesign",
          query: {
            option: option,
          },
        });
      }
    };

    activityOptionList();
    return { ...toRefs(dataMap), goTo };
  },
});
</script>

<style lang="less" scoped>
.font-40 {
  font-size: 40px;
  line-height: 60px;
  height: 60px;
}
.color1 {
  color: #5adfcf;
}
.color2 {
  color: #d6beab;
}
.color3 {
  color: #b4ccfb;
}
.index {
  color: #787878;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  padding: 10px;
  font-size: 14px;
  .area {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    & + .area {
      margin-top: 20px;
    }
  }
}
.header {
  border-bottom: 1px solid #e2e2e2;
  color: #787878;
  line-height: 40px;
  padding: 0 20px;
}
.box {
  text-align: center;
  padding: 20px 0;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.flex33 {
  flex: 0 0 33.333333%;
}
.flex50 {
  flex: 0 0 50%;
}
.text {
  line-height: 21px;
  height: 21px;
}
.none {
  font-size: 40px;
  text-align: center;
  padding: 35vh 0 0;
}
</style>